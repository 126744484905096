<template>
    <div>
        <div>
          <div class="landlord-table table-responsive">
            <div class="top-text-button">
              <h5>Landlords <span>{{ landlords.length || 0 }}</span></h5>
              <button @click="addLandlord()" class="btn"><span>+</span> Add landlord</button>
            </div>
            <div class="m-auto text-center mt-4" v-if="lloading"><Loader/></div>
            <table class="table" v-if="!lloading && landlords.length != 0">
                <thead>
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email address</th>
                        <th scope="col">Phone number</th>
                        <th scope="col">No. of property</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr  v-for="(item, index) in landlords"
                          :key="index"
                          :value="item.id">
                      <td>{{ item.usercode }}</td>
                      <td>{{ item.firstname }} {{ item.lastname }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.phone }}</td>
                      <td>{{ item.property_count || 0 }}</td>
                      <td>
                        <img class="dropdown-toggle" style="cursor: pointer;" src="../assets/icons/menu-icon.svg" alt="menu" data-toggle="dropdown" aria-expanded="false">
                        <div class="dropdown-menu">
                          <a @click="viewLandlordDetails(item.id)" class="dropdown-item">View details</a>
                          <a @click="addProperty(item.id)" class="dropdown-item">Add property (unit)</a>
                          <a class="dropdown-item">Generate report</a>
                          <a class="dropdown-item">Delete</a>
                        </div>
                      </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="landlords.length == 0 && !lloading">
              <EmptyState :emptyData="emptyDataObject" />
            </div>
            <!-- <div class="empty-state" v-if="landlords.length == 0 && !lloading">
              <img src="../assets/icons/empty-state.svg" alt="No data">
              <h5>No landlord registred yet</h5>
              <h6>Start onboarding landlords to begin earning commissions.</h6>
            </div> -->
             
        </div>
      </div>
    </div>
</template>

<script>
// import CF from "../helpers/currencyFormatter";
import Loader from './Loader';
import properties from "../services/properties";
import EmptyState from "./EmptyState.vue";

// import $ from "jquery";
export default {
  name: "LandlordLists",
  components: {
    Loader,
    EmptyState
  },
  data() {
    return {
        loading: false,
        lloading: false,
        userID: '',
        landlords: [],
        emptyDataObject: {
        header: 'No landlord registred yet.',
        option: 'tart onboarding landlords to begin earning commissions.'
      },
    };
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userID = user.id;

  },
  mounted() {
   this.getLandlords();
  },
  beforeDestroy() {
  },
  methods: {
    addLandlord() {
      this.$router.push("/user/register-landlord");
    },
    viewLandlordDetails(id) {
      this.$router.push(`/user/landlord-details/${id}`);
    },
    addProperty(id) {
      this.$router.push(`/user/addpropertytolandlord/${id}`);
    },
    getLandlords() {
      // this.propperties.lanlords()
      this.lloading = true;
      properties.lanlordsList(this.userID)
        .then((res) => {
          this.lloading = false;
          if (res.success) {
            // console.log("landlords", res);
            this.landlords = res.landlords;
          } else {
            this.$toast.error("ERROR");
          }
        })
        .catch((err) => {
          console.log(err);
          this.lloading = false;
        });
    }

  },
};
</script>

<style lang="scss" scoped>

.empty-state {
  text-align: center;
  background: #ffffff;
  padding: 20px 15px;
  h5 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #2B3352;
  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #555C74;
  }
}
.top-text-button {
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #555C74;
    span {
      border: 1px solid #E4E7EC;
      background: #F9FAFB;
      border-radius: 50px;
      padding: 3px 6px;
      font-size: 12px;
    }
  }
  button {
    background: #0033EA;
    color: #ffffff;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    // line-height: 20px;
    text-align: center;
    min-height: 40px;
    box-shadow: none;
    outline: 0;
  }
}
.landlord-table {
  border: 2px solid #EAECF0;
  border-radius: 20px;
}

.table thead th {
    border-bottom: none;
    
}
.table td {
    border-top: 1px solid #EAECF0;
}
.table th {
    border-top: none;
}

thead > tr {
    background: #EAECF0;
}
thead th {
    white-space: nowrap;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #667085
}

tbody td {
    padding: 18px .75rem;
    white-space: nowrap;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #667085
}
.dropdown-menu {
  box-shadow: 0px 4px 8px 0px #2E2E2E1A, 0px 15px 15px 0px #2E2E2E17, 0px 35px 21px 0px #2E2E2E0D, 0px 62px 25px 0px #2E2E2E03, 0px 96px 27px 0px #2E2E2E00;
  background: #ffffff;
  border-radius: 8px;
  border: none;
}
.dropdown-item {
  font-family: Lato;
  font-size: 16px;
  font-weight: 200;
  line-height: 24px;
  text-align: left;
  color: #555C74 !important;
  margin-bottom: 8px;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
  box-shadow: none;
  outline: 0;
}
.dropdown-item:active {
  background: #e9ecef;
  color: #555C74
}
</style>
